export class Devices {
    async init() {
        this.video = null;
        this.audio = null;
        this.out = null;

        this.currentVideo = 0;
        this.currentAudio = 0;

        try {
            /*const panTiltZoomPermissionStatus = await navigator.permissions.query({
                name: "camera",
                panTiltZoom: true
            });*/

            const supports = navigator.mediaDevices.getSupportedConstraints();
            console.log(supports);

            /*panTiltZoomPermissionStatus.addEventListener("change", () => {
                // User has changed PTZ permission status.
            });*/
        } catch (error) {
            console.log(error);
        }
        await this.getDevices();

        console.log(this.video);
        console.log(this.audio);
    }

    nextCamera() {
        this.currentVideo++;
        if(this.currentVideo > this.video.length - 1) {
            this.currentVideo = 0;
        }

        return this.video[this.currentVideo].deviceId;
    }

    getVideoDevice() {
        console.log(this.video[this.currentVideo]);
        return this.video[this.currentVideo].deviceId;
    }

    getAudioDevice() {
        return this.audio[this.currentAudio].deviceId;
    }

    async getDevices() {
        await this.getStreamForDeviceCheck();
        const deviceInfos = await this.getDevicesEnum();
        var devices = this.gotDevices(deviceInfos)

        this.video = devices.videoinput;
        this.audio = devices.audioinput;
        this.out = devices.audiooutput;
    }

    async getStreamForDeviceCheck() {

        // High resolution video constraints makes browser to get maximum resolution of video device.
        const constraints = {
            video: {
                width: {ideal: 1080},
                height: {ideal: 720},
                aspectRatio: 1.777777778,
                zoom: true,
                //facingMode: this.shouldFaceUser ? 'user' : 'environment',
            },
            audio: {
                //deviceId: { exact: audioId },
                autoGainControl: false,
                channelCount: 1,
                echoCancellation: true,
                noiseSuppression: false,
                volume: 1.0
            }
        };

        return await navigator.mediaDevices.getUserMedia(constraints);
    }

    async getDevicesEnum() {
        return await navigator.mediaDevices.enumerateDevices();
    }

    gotDevices(deviceInfos) {

        let devices = {
            'audioinput': [],
            'audiooutput': [],
            'videoinput': [],
            'other': [],
        };

        for (let i = 0; i !== deviceInfos.length; ++i) {

            const deviceInfo = deviceInfos[i];

            let info = {};

            info.deviceId = deviceInfo.deviceId;

            if (deviceInfo.kind === 'audioinput') {

                info.label = deviceInfo.label || `microphone ${devices.audioinput.length + 1}`;
                devices.audioinput.push(info);
            } else if (deviceInfo.kind === 'audiooutput') {

                info.label = deviceInfo.label || `speaker ${devices.audiooutput.length + 1}`;
                devices.audiooutput.push(info);
            } else if (deviceInfo.kind === 'videoinput') {

                info.label = deviceInfo.label || `camera ${devices.videoinput.length + 1}`;
                devices.videoinput.push(info);
            } else {

                info.label = deviceInfo.label || `other ${devices.other.length + 1}`;
                devices.other.push(info);
            }
        }

        return devices;
    }
}
