var ws = new WebSocket('wss://playout.mhu.news:8444/magicmirror');

var zoom_max = 100;
var zoom_min = 100;
var zoom_step = 2;
var zoomCur = 100;
var scaling = false;
var old_dist = 0;
var current_track = null;

function sendMessage(message) {
    var jsonMessage = JSON.stringify(message);
    console.log('Sending message: ' + jsonMessage);
    ws.send(jsonMessage);
}

var user = null;

export class WebRtc {

    constructor(inputVideo, userset, componet, videoId, audioId) {
        this.videoInput = document.getElementById(inputVideo);
        this.webRtcPeer = null;
        this.state = null;
        this.videoStream = null;
        this.componet = componet;
        this.shouldFaceUser = true;

        this.remotesStream = [];

        user = userset;



        this.getUserMedia(videoId, audioId);

        const self = this;
        ws.onmessage = function (message) {
            var parsedMessage = JSON.parse(message.data);
            console.info('Received message: ' + message.data);

            switch (parsedMessage.id) {
                case 'startResponse':
                    self.startResponse(parsedMessage);
                    break;
                case 'error':
                    console.log('Error message from server: ' + parsedMessage.message);
                    break;
                case 'viewerResponse':
                    self.viewerResponse(parsedMessage);
                    break;
                case 'iceCandidate':
                    self.webRtcPeer.addIceCandidate(parsedMessage.candidate)
                    break;
                case 'ffmpeg':
                    //console.log('From ffmpeg:', parsedMessage.message);
                    break;
                case 'addtoefir':
                    var remoteStream = {
                        id: parsedMessage.userId,
                        webRtcPeer: null
                    };
                    self.remotesStream.push(remoteStream);
                    self.viewer(remoteStream);
                    //console.log('From ffmpeg:', parsedMessage.message);
                    break;
                case 'delefir':
                    self.stopviewer(parsedMessage.userId);
                    //console.log('From ffmpeg:', parsedMessage.message);
                    break;
                default:
                    console.log('Unrecognized message', parsedMessage);
            }
        }
    }

    stopviewer(id) {
        var s_ = [];
        for(var i = 0; i < this.remotesStream.length; i++) {
            if(this.remotesStream[i].id !== id)
                s_.push.remotesStream[i];
        }
        this.remotesStream = s_;
        var element = document.getElementById('remote_' + id);
        element.parentNode.removeChild(element);

        if(this.remotesStream.length < 1) {
            var videocontain = document.getElementById('remote')
            videocontain.className = 'remote-stream'
        }
        this.componet.stopEfir();
    }

    viewer(remoteStream) {
        var video = document.createElement('iframe');
        video.src = 'https://ukraine-inform.com/stream/new/?user=' + remoteStream.id;
        video.id = 'remote_' + remoteStream.id;

        var videocontain = document.getElementById('remote')
        videocontain.appendChild(video);
        videocontain.className = 'show remote-stream'
        this.componet.startEfir();
    }

    getRemoteT() {
        var remoteStream = this.getRemote(0);
        console.log('Call not accepted for the following reason: ', remoteStream);
        console.log(remoteStream.webRtcPeer.getRemoteStream());
        var remoteVideo_ = document.getElementById('remote_0');
        remoteVideo_.srcObject = remoteStream.webRtcPeer.getRemoteStream();
        remoteVideo_.onloadedmetadata = function () {
            remoteVideo_.play();
        };
    }

    getRemote(id) {
        for(var i = 0; i < this.remotesStream.length; i++) {
            if(this.remotesStream[i].id == id)
                return this.remotesStream[i];
        }
        return null;
    }

    viewerResponse(message) {
        if (message.response != 'accepted') {
            var errorMsg = message.message ? message.message : 'Unknow error';
            console.warn('Call not accepted for the following reason: ' + errorMsg);
            //dispose();
        } else {
            var remoteStream = this.getRemote(message.remote);
            console.log('Call not accepted for the following reason: ' + remoteStream);
            remoteStream.webRtcPeer.processAnswer(message.sdpAnswer);
        }
    }

    getUserMedia(videoId, audioId) {
        console.log(videoId, audioId);
        const self = this;


        var constraints = {
            video: {
                deviceId: { exact: videoId },
                width: {ideal: 1080},
                height: {ideal: 720},
                aspectRatio: 1.777777778,
                zoom: true,
                //facingMode: this.shouldFaceUser ? 'user' : 'environment',
            },
            audio: {
                //deviceId: { exact: audioId },
                autoGainControl: false,
                channelCount: 1,
                echoCancellation: true,
                noiseSuppression: false,
                volume: 1.0
            }
        };
        console.log(constraints);

        console.info('Requested Constraint To Input Devices', constraints);

        return navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {

                console.info('Received Media Stream From Input Device', stream);

                self.videoStream = stream;

                let elem = self.videoInput;

                if (elem) {
                    elem.srcObject = stream;
                    elem.onloadedmetadata = function () {
                        elem.play();

                        current_track = stream.getVideoTracks()[0];
                        console.log(current_track);
                        window.setTimeout(() => (
                            self.onCapabilitiesReady(current_track.getCapabilities())
                        ), 1000);
                    };
                }

                return new Promise(function (resolve) {
                    resolve(stream);
                });
            })
            .catch(function (error) {

                console.error('Can\'t Get Media Stream From Input Device', error);

                return new Promise(function (resolve, reject) {
                    reject(error);
                });
            });
    }

    setDevices(videoId, audioId) {
        console.log(videoId, audioId);
        this.shouldFaceUser = !this.shouldFaceUser;
        const self = this;
        if (self.videoStream) {
            self.videoStream.getTracks().forEach(track => {
                track.stop();
            });
        }

        let newConstraints = {
            video: {
                deviceId: { exact: videoId },
                width: {ideal: 1080},
                height: {ideal: 720},
                aspectRatio: 1.777777778,
                zoom: true,
                //facingMode: this.shouldFaceUser ? 'user' : 'environment',
            },
            audio: {
                //deviceId: { exact: audioId },
               autoGainControl: false,
                channelCount: 1,
                echoCancellation: true,
                noiseSuppression: false,
                volume: 1.0
            }
        }



        navigator.mediaDevices.getUserMedia(newConstraints)
            .then(function (stream) {
                console.info('Received Media Stream From Input Device', stream);

                self.videoStream = stream;

                let elem = self.videoInput;

                if (elem) {
                    elem.srcObject = stream;
                    elem.onloadedmetadata = function () {
                        elem.play();

                        current_track = stream.getVideoTracks()[0];
                        console.log(current_track);
                        window.setTimeout(() => (
                            self.onCapabilitiesReady(current_track.getCapabilities())
                        ), 1000);
                    };
                }

                if(self.webRtcPeer) {
                    var senders = self.webRtcPeer.peerConnection.getSenders();
                    console.log(senders);
                    var lst = self.webRtcPeer.peerConnection.getLocalStreams()[0];
                    console.log(lst);
                    var tracks = stream.getTracks();
                    console.log(tracks);
                    var i = tracks.length;
                    while (--i > -1) {
                        senders[i].replaceTrack(tracks[i]);
                    }
                }

                return new Promise(function (resolve) {
                    resolve(stream);
                });
            })
            .catch(function (error) {
                console.error('Can\'t Get Media Stream From Input Device', error);
                return new Promise(function (resolve, reject) {
                    reject(error);
                });
            });
    }

    onCapabilitiesReady(capabilities) {
        console.log(capabilities);
        zoom_max = capabilities.zoom.max;
        zoom_min = capabilities.zoom.min;
        zoom_step = capabilities.zoom.step;
        zoomCur = zoom_min;

        function setZoom(zoom) {
            console.log(zoom)
            current_track.applyConstraints({
                advanced: [{zoom: parseInt(zoom)}]
            })
                .catch(e => console.log(e));
        }

        document.addEventListener('touchstart', function(e){
            if (e.touches.length === 2) {
                scaling = true;
            }
        });

        document.addEventListener('touchmove', function(e){
            if (scaling) {
                var dist = Math.hypot(
                    e.touches[0].pageX - e.touches[1].pageX,
                    e.touches[0].pageY - e.touches[1].pageY);
                if(old_dist>dist) {
                    if(zoomCur - zoom_step > zoom_min) {
                        zoomCur -= zoom_step;
                        setZoom(zoomCur);
                    }
                } else if(old_dist<dist) {
                    if(zoomCur + zoom_step < zoom_max) {
                        zoomCur += zoom_step;
                        setZoom(zoomCur);
                    }
                }
                old_dist = dist;
            }
        });
    }

    start() {
        const self = this;
        if (!self.webRtcPeer) {
            var options = {
                localVideo: self.videoInput,
                onicecandidate : self.onIceCandidate,
                videoStream: self.videoStream,
            }

            // eslint-disable-next-line no-undef
            self.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function(error) {
                if(error) return console.log(error);

                this.generateOffer(self.onOfferPresenter);
            });
        }
    }

    stop() {
        if (this.webRtcPeer) {
            this.webRtcPeer.dispose();
            this.webRtcPeer = null;

            var message = {
                id: 'stop'
            }
            sendMessage(message);
        }
    }

    onIceCandidate(candidate) {
        console.log('Local candidate' + JSON.stringify(candidate));

        var message = {
            id : 'onIceCandidate',
            candidate : candidate
        }
        sendMessage(message);
    }

    onOfferPresenter(error, offerSdp) {

        if (error) return console.log(error);

        var message = {
            id : 'start',
            userName: user.name,
            userId: user.id,
            email: user.email,
            phone: user.phone,
            sdpOffer : offerSdp
        };
        sendMessage(message);
    }

    onOfferViewer(error, offerSdp) {
        if (error) return console.log(error);

        var message = {
            id : 'viewer',
            sdpOffer : offerSdp
        }
        sendMessage(message);
    }

    startResponse(message) {
        console.log('SDP answer received from server. Processing ...');
        this.webRtcPeer.processAnswer(message.sdpAnswer);
    }
}
